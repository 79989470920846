import React from 'react'
import Layout from '../components/layout'

import LinkButton from '../components/link-button'

const SupportPage = () => (
  <Layout>
    <section className="support-callout">
      <h2 className="support-callout__title">Support and Frequently Asked Questions</h2>
      <p>
        We do our best to make Galactic Rex easy to play. However, every once in awhile you might
        have a question and the answer isn't as obvious as we'd like. If you don't find your question
        and answer here you can use the Contact Us link at the bottom of the page to send us an email.
      </p>
    </section>
    <section>
      <h3>FAQ Table of Contents</h3>
      <ul>
        <li><a href="#how-to-control-rex">How do I control Rex?</a></li>
        <li><a href="#how-to-fire-weapons">How do I fire Rex's weapons?</a></li>
        <li><a href="#how-to-crash-logs">How do I get crash logs and what do I do with them?</a></li>
      </ul>
    </section>
    <section id="how-to-control-rex">
      <h3>How do I control Rex?</h3>
      <p>
        You can control Rex by dragging your finger around on the right side of the screen. 
        Moving your finger causes Rex to fly around the screen. Don't forget that this only
        works on the right half of your device's screen.
      </p>        
    </section>
    <section id="how-to-fire-weapons">
      <h3>How do I fire Rex's weapons?</h3>
      <p>
        You can fire Rex's regular blaster by tapping on the right half of your device's screen.
        Tap and hold on the right half of your screen to charge and fire a bomb.
      </p>
    </section>
    <section id="how-to-crash-logs"> 
      <h3>How do I get crash logs and what do I do with them?</h3>
      <p>
        To help troublshoot some bugs, the Galactic Rex team may need you to
        submit crash logs from your iOS device.
      </p> 
      <p><strong>iOS Instructions (for iOS 11 and later)</strong></p>
      <ol>
        <li>
          Navigate to your device Settings > Privacy > Analytics > Analytics Data.
        </li>
        <li>
          Scroll through this list to find Galactic Rex rows. The list is alphabetical.
        </li>
        <li>
          Tap one of these events.
        </li>
        <li>
          Tap the share icon at the top right.
        </li>
        <li>
          Email the event to <a href="mailto:support@galacticrex.com">Support</a>
        </li>
      </ol>
    </section>
    <section className="faq-contact-us">
      <h3>Still have a question? We're here to help.</h3>
      <LinkButton title="Contact Us" href="mailto:support@galacticrex.com" />
    </section>
  </Layout>
)

export default SupportPage