import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import SocialItems from '../Social/SocialItems'

const Nav = ({ siteTitle, navLinks }) => {
  return (
    <nav className="site-nav">
      <div className="site-nav-options">

        <ul className="site-nav-items">
          <li key={siteTitle} className="site-nav-item">
            <Link to="/" className="site-nav-logo site-nav-item-link">
              {siteTitle}
            </Link>
          </li>
          {
            navLinks.map(link =>
              <li key={link.name} className="site-nav-item">
                <Link className="site-nav-item-link" to={link.link}>{link.name}</Link>
              </li>
            )
          }
        </ul>
      </div>

      <div className="site-nav-social">
        <SocialItems />
      </div>
    </nav>
  )
}

Nav.propTypes = {
  siteTitle: PropTypes.string,
  navLinks: PropTypes.array,
}

Nav.defaultProps = { 
  siteTitle: '',
  navLinks: [],
}

export default Nav