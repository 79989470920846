import React from 'react'

import PropTypes from 'prop-types'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const SocialItem = ({link, className, iconComponent}) => {
  return (
    <li className="social-item">
      <OutboundLink href={link} className={"social-item__" + className}>
        {iconComponent}
      </OutboundLink>
    </li>
  )
}

SocialItem.propTypes = {
  link: PropTypes.string,
  className: PropTypes.string,
  iconComponent: PropTypes.element,
}

SocialItem.defaultProps = { 
  link: '',
  className: '',
  iconComponent: null
}

export default SocialItem