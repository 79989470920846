import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Nav from './Navigation/Nav'
import Footer from './Footer'
import '../assets/stylesheets/application.scss'
import favicon from '../assets/images/favicon.png'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            navLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <div className="site">
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Help Rex return home in this epic adventure across space and time. Available for iOS.' },
              { name: 'keywords', content: 'iOS, game, app, space, arcade' },
            ]}
            link={[
              { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Nav navLinks={data.site.siteMetadata.navLinks} siteTitle={data.site.siteMetadata.title} />
          <div className="site-content">
            {children}
          </div>
          <Footer siteTitle={data.site.siteMetadata.title} />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
