import React from 'react'

import { FaFacebook, FaTwitter, FaInstagram, FaEnvelope, FaYoutubeSquare } from 'react-icons/fa'

import SocialItem from './SocialItem'

const SocialItems = () => {
  const socialMetadata = [
    {
      "link": "https://www.facebook.com/Galactic-Rex-221651075417532/",
      "className": "facebook",
      "iconComponent": <FaFacebook />,
    },
    {
      "link": "https://twitter.com/galactic_rex",
      "className": "twitter",
      "iconComponent": <FaTwitter />,
    },
    {
      "link": "https://www.instagram.com/galactic_rex/?igshid=19d1qtcgyp2ho",
      "className": "instagram",
      "iconComponent": <FaInstagram />,
    },
    {
      "link": "https://www.youtube.com/channel/UCtk4F26nIEVChHPJ94KE0NQ/",
      "className": "youtube",
      "iconComponent": <FaYoutubeSquare />,
    },
    {
      "link": "mailto:support@galacticrex.com",
      "className": "email",
      "iconComponent": <FaEnvelope />,
    }
  ]

  return (
    <ul className="social-items">
      {
        socialMetadata.map(social => 
          <SocialItem key={social.className} link={social.link} className={social.className} iconComponent={social.iconComponent} />
        )
      }
    </ul>
  )
}

export default SocialItems