import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import SocialItems from './Social/SocialItems'

const Footer = ({siteTitle}) => {
  return (
    <footer>
      <div className="site-nav-options">
        <ul className="site-nav-items">
          <li key={siteTitle} className="site-nav-item">
            <Link to="/" className="site-nav-item-link footer-nav-item">
              {siteTitle}
            </Link>
          </li>
          <li key="videos" className="site-nav-item footer-nav-item">
            <Link to="/videos" className="site-nav-item-link">
              Videos
            </Link>
          </li>
          <li key="characters" className="site-nav-item footer-nav-item">
            <Link to="/characters" className="site-nav-item-link">
              Characters
            </Link>
          </li>
          <li key="support" className="site-nav-item footer-nav-item">
            <Link to="/support" className="site-nav-item-link">
              Support
            </Link>
          </li>
          <li key="privacy" className="site-nav-item footer-nav-item">
            <Link to="/privacy" className="site-nav-item-link">
              Privacy
            </Link>
          </li>
        </ul>
      </div>
      <div>
        <SocialItems />
      </div>
      <div className="footer-copyright">
        <div className="footer-copyright__title">{siteTitle}</div>
        <div className="footer-copyright__copyright">&copy; 2019 Galactic Rex</div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = { 
  siteTitle: '',
}

export default Footer